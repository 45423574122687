import React from "react";
import { Link } from "gatsby";

import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";

import logo from "./images/logo.svg";

const LinkItem = props => (
  <NavItem className="">
    <Link className="nav-link text-dark navigation__navlinks" to={props.to}>
      {props.name}
    </Link>
  </NavItem>
);

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      isOpen: false
    };
  }

  // componentWillMount () {
  //   document.addEventListener('mousedown', this.handleClickOutside)
  // }
  // componentWillUnmount () {
  //   document.removeEventListener('mousedown', this.handleClickOutside)
  // }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  closeNavbar() {
    this.setState({
      isOpen: false
    });
  }

  handleClickOutside(event) {
    const t = event.target;
    if (this.state.isOpen && !t.classList.contains(`navbar-toggler`)) {
      this.closeNavbar();
    }
  }

  render() {
    return (
      <Navbar light expand="md">
        <NavbarToggler onClick={this.toggle} />
        <Link to="/#home" className="navbar-brand d-block d-sm-none mr-0">
          <svg
            width={110}
            height={65}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <image xlinkHref={logo} width={110} height={65} />
          </svg>
        </Link>
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav navbar>
            <LinkItem
              onClick={this.closeNavbar}
              name="Artists"
              to="/#artists"
            />
            <LinkItem onClick={this.closeNavbar} name="News" to="/#news" />
            <LinkItem
              onClick={this.closeNavbar}
              name="Contact"
              to="/#contact"
            />
            <LinkItem onClick={this.closeNavbar} name="About" to="/about" />
          </Nav>
        </Collapse>
        <Link to="/#home" className="navbar-brand d-none d-sm-block">
          <svg
            width={200}
            height={100}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <image xlinkHref={logo} width={200} height={100} />
          </svg>
        </Link>
      </Navbar>
    );
  }
}
