import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  Form
} from "reactstrap";
import { OutboundLink } from "gatsby-plugin-gtag";

import "./footer.scss";
import styles from "./footer.module.css";

export default () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        site {
          siteMetadata {
            title
            adminEmail
          }
        }
        contact: markdownRemark(
          fileAbsolutePath: { regex: "about/subscribe.md$/" }
        ) {
          html
        }
      }
    `}
    render={data => (
      <footer className="footer">
        <Container>
          <Row>
            <Col id="newsletter" sm="12" md="6" className="pb-5">
              <h2>Newsletter</h2>
              <div
                className="pb-3"
                dangerouslySetInnerHTML={{ __html: data.contact.html }}
              />
              <Form
                name="Newsletter"
                method="POST"
                action="/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <Input name="bot-field" className="d-none" />
                <InputGroup className={styles.inputGroup}>
                  <Input
                    name="Email"
                    type="email"
                    placeholder="Email address..."
                    className={styles.input}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      color="default"
                      className={styles.submitButton}
                      type="submit"
                    >
                      Subscribe
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </Form>
            </Col>
            <Col sm="12" md="6" className="social-links">
              <h2>Links</h2>
              <ul>
                <li>
                  <OutboundLink
                    href="https://soundcloud.com/eingangbookings"
                    target="_blank"
                  >
                    Soundcloud
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    href="https://instagram.com/eingangbookings"
                    target="_blank"
                  >
                    Instagram
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    href="https://facebook.com/eingangbookings/"
                    target="_blank"
                  >
                    Facebook
                  </OutboundLink>
                </li>
                <li>
                  <OutboundLink
                    href="https://twitter.com/eingangbookings"
                    target="_blank"
                  >
                    Twitter
                  </OutboundLink>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="pt-5">
            <Col>
              <p className="copyright-notice">
                Copyright © Eingang Bookings {new Date().getFullYear()} - <Link to="imprint">Imprint</Link>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    )}
  />
);
