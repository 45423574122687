import React from "react";
import Helmet from "react-helmet";
import Navigation from "../nav";
import PropTypes from "prop-types";
import BackgroundSection from "./background";
import Footer from "./footer";

import "./index.scss";

export default class Layout extends React.Component {
  static propTypes = {
    imageData: PropTypes.object
  };

  constructor(props) {
    super(props);

    // eslint-disable-next-line
    let user;
    if (typeof window !== `undefined`) {
      user = window.netlifyIdentity && window.netlifyIdentity.currentUser();
    }
  }

  render() {
    return (
      <div className="App">
        <Helmet />
        <BackgroundSection imageData={this.props.imageData}>
          <Navigation />
          <div className={`content`}>
            <div className="pageContent">{this.props.children}</div>
            <div className="footer">
              <Footer />
            </div>
          </div>
        </BackgroundSection>
      </div>
    );
  }
}
