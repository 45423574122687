import React from "react";
import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import styles from "./background.module.css";

const BackgroundSection = ({ children, className, imageData }) => (
  <StaticQuery
    query={graphql`
      query {
        defaultImage: file(
          sourceInstanceName: { eq: "assets" }
          relativePath: { eq: "swatches/noise3_magenta.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const defaultImageData = data.defaultImage.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag={`section`}
          className={className || `Section`}
          fluid={imageData || defaultImageData}
          backgroundColor={`#FFFFFF`}
          styles={styles.background}
        >
          <div className={styles._children}>{children}</div>
        </BackgroundImage>
      );
    }}
  />
);

export default BackgroundSection;
